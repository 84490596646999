.home{
    position: absolute;
    width: 100%;
    height: calc(100vh - 80px);
    background-position: center;
    background-size: cover;
    background-image: linear-gradient(to bottom, rgb(2, 34, 43), #0003), url("../assets/fond2.jpg");
    
}

.container{
    height: 100%;
    display: flex;
    flex-direction: column; 
}

.title{
    margin-top: 20px;
    font-size: 65px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: #fff;
    text-align: center;
}

.all-logo{
    position: relative;
    margin-top: 100px;
    display: flex;
}

.logo-avs{
    position: absolute;
    margin-top: 100px;
    left: 100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-avs .title-avs{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 35px;
    color: #fff;
}

.logo-avs .avs{
    height: 90px;
    margin-top: 10px;
    aspect-ratio: 16/9;
    border-radius: 20px;
}

.logo-ivertech{
    position: absolute;
    margin-top: 100px;
    right: 100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-ivertech .title-ivertech{
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 35px;
    color: #fff;
}

.logo-ivertech .ivertech{
    height: 90px;
    margin-top: 11px;
    margin-bottom: 3px;
    aspect-ratio: 16/9;
    border-radius: 20px;
}


@media (max-width:850px){
    .title{
        font-size: 40px;
    }
    .logo-avs .title-avs{
        font-size: 25px;
    }
    .logo-ivertech .title-ivertech{
        font-size: 25px;
    }
}

@media (max-width:630px){

    .title{
        font-size: 35px;
    }
    .logo-avs .title-avs{
        font-size: 15px;
    }
    .logo-ivertech .title-ivertech{
        font-size: 15px;
    }
}

@media (max-width:535px){

    .title{
        font-size: 30px;
    }

    .all-logo{
        margin-top: 60px;
    }

    .all-logo .logo-avs{
        font-size: 10px;
        left: 50px;
    }
    .all-logo .logo-ivertech{
        font-size: 10px;
        right: 50px;
        top: 150px;
    }
    
}